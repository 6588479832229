import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    Tooltip,
    OverlayTrigger
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import TagPill from "../../../components/tagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";
import DetailOverviewRow from "../../../components/detail/DetailOverviewRow";

import DetailOverviewQRCode from "../../../components/detail/DetailOverviewQRCode";
import LeadDetailSetStatusModal from "./modal/LeadDetailSetStatusModal";
import LeadDetailSetAssignedUserModal from "./modal/LeadDetailSetAssignedUserModal";
import LeadDetailSetCompletedByUserModal from "./modal/LeadDetailSetCompletedByUserModal";
import DetailOverviewComments from "../../../components/detail/DetailOverviewComments";
import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";
import AppleMapsModal from "../../../components/apple-maps/AppleMapsModal";
import LeadExcelCopyButton from "./components/LeadExcelCopyButton";
import ClickCopy from "../../../components/copy/ClickCopy";
import LeadDetailNearLeadsModal from "./modal/LeadDetailNearLeadsModal";
import NumberFormatter from "../../../components/formatters/NumberFormatter";
import AddressMapButtons from "../../../components/address/AddressMapButtons";
import CoordinatesMapButtons from "../../../components/address/CoordinatesMapButtons";
import CallLink from "../../../components/detail/CallLink";

class LeadDetailOverview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            comments: null,
            newCommentLoading: false,
            error: null,
            errorComments: null,
            errorNewComment: null,
            errorCoordinates: null,
            qrCodeLoaded: false,

            showSetStatusModal: false,
            showAppleMapsModal: false,
            showNearLeadsModal: false
        };
    }

    componentDidMount() {
        this.getLeadComments();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.leadId !== null && this.props.leadId !== prevProps.leadId) {
            this.getLeadComments(true);
            this.setState({ showNearLeadsModal: false });
        }
    }

    setLeadProps(props) {
        this.props.setLead((prevLead) => {
            return { ...prevLead, ...props };
        })
    }

    clearLeadWarnings() {
        this.setLeadProps({ warnings: [] });
    }

    getLeadComments(clearComments = false) {
        if(clearComments) {
            this.setState({ comments: null });
        }
        this.setState({ errorComments: null });
        axios.post("/getLeadComments", { lead: this.props.leadId, new: true })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ comments: response.data.data });
                } else {
                    this.setState({ errorComments: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ errorComments: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    addComment(message, files, clearContent) {
        if(message.trim().length === 0) {
            this.setState({ errorNewComment: "De notitie kan niet leeg zijn." });
            return;
        }
        this.setState({ errorNewComment: null, newCommentLoading: true });
        axios.post("/addLeadComment", { lead: this.props.leadId, message })
            .then(async (response) => {
                if(response.data.valid) {
                    if(!await this.uploadFilesIfNeeded(response.data.comment.id, files)) {
                        this.setState({ errorNewComment: "Er is een fout opgetreden. Niet alle foto's zijn geupload." });
                    }
                    this.getLeadComments();
                    this.clearLeadWarnings();
                    clearContent();
                } else {
                    this.setState({ errorNewComment: "Er ging iets fout bij het plaatsen van je notitie. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ errorNewComment: "Er ging iets fout bij het plaatsen van je notitie. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ newCommentLoading: false });
            });
    }

    async uploadFilesIfNeeded(commentId, files) {
        for(const file of files) {
            if(!await this.uploadFile(commentId, file)) {
                return false;
            }
        }
        return true;
    }

    async uploadFile(commentId, file) {
        if(file.file === null) {
            return false;
        }

        const formData = new FormData();
        formData.append("leadCommentId", commentId);
        formData.append("file", file.file, file.file.name);

        const fileExtension = file.file.name.split('.').pop().toLowerCase();
        let apiEndpoint = "/uploadFile";
        if(fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png") {
            apiEndpoint = "/uploadImage";
        }

        const response = await axios.post(apiEndpoint, formData, {
            transformRequest: (data, headers) => {
                headers.setContentType(undefined);
                return data;
            }
        });

        return !(!response.data || !response.data.valid);
    }

    onCommentAdded = this.commentAdded.bind(this);
    commentAdded() {
        this.getLeadComments();
    }

    commentUpdated(comment) {
        this.setState((prevState) => {
            const comments = prevState.comments.map((searchComment) => {
                if(searchComment.id === comment.id && searchComment.commentType === comment.commentType) {
                    return comment;
                }
                return searchComment;
            });
            return { comments };
        });
    }

    commentDeleted() {
        this.getLeadComments(this.props.leadId);
    }

    refreshCoordinates(id) {
        this.setState({ errorCoordinates: null });
        axios.post('/refreshLeadCoordinates', { leadId: this.props.leadId })
            .then((response) => {
                if(response.data.valid) {
                    this.setLeadProps({
                        latitude: response.data.data.latitude,
                        longitude: response.data.data.longitude,
                        locationLastUpdate: response.data.data.locationLastUpdate
                    });
                } else {
                    if(response.data.error === "TOO_QUICK") {
                        this.setState({ errorCoordinates: "De locatie van deze lead is 5 minuten geleden al bijgewerkt. Wacht even met het verversen van de locatie." });
                    } else if(response.data.error === "GOOGLE_ERROR") {
                        if(response.data.google === "ZERO_RESULTS") {
                            this.setState({ errorCoordinates: "Er konden geen coördinaten voor dit adres gevonden worden." });
                        } else {
                            this.setState({ errorCoordinates: "Er ging iets fout bij de aanvraag voor coördinaten bij Google. Probeer het later opnieuw. (" + response.data.google + ")" });
                        }
                    } else {
                        this.setState({ errorCoordinates: "Er ging iets fout bij het verversen van de coördinaten. Probeer het later opnieuw. (" + response.data.error + ")" });
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ errorCoordinates: "Er ging iets fout bij het verversen van de coördinaten. Probeer het later opnieuw." });
            });
    }

    onHandleStatusChanged = this.handleStatusChanged.bind(this);
    handleStatusChanged(status) {
        this.handleCloseSetStatusModal();
        this.setLeadProps({ status });
        this.clearLeadWarnings();
        this.getLeadComments(this.props.leadId);
    }

    onHandleCloseSetStatusModal = this.handleCloseSetStatusModal.bind(this);
    handleCloseSetStatusModal() {
        this.setState({ showSetStatusModal: false });
    }

    onHandleAssignedUserChanged = this.handleAssignedUserChanged.bind(this);
    handleAssignedUserChanged(assignedUser) {
        this.handleCloseSetAssignedUserModal();
        this.setLeadProps({ assignedUser });
        this.clearLeadWarnings();
        this.getLeadComments(this.props.leadId);
    }

    onHandleCompletedByUserChanged = this.handleCompletedByUserChanged.bind(this);
    handleCompletedByUserChanged(completedByUser) {
        this.handleCloseSetCompletedByUserModal();
        this.setLeadProps({ completedByUser });
        this.clearLeadWarnings();
        this.getLeadComments(this.props.leadId);
    }

    onHandleCloseSetAssignedUserModal = this.handleCloseSetAssignedUserModal.bind(this);
    handleCloseSetAssignedUserModal() {
        this.setState({ showSetAssignedUserModal: false });
    }

    onHandleCloseSetCompletedByUserModal = this.handleCloseSetCompletedByUserModal.bind(this);
    handleCloseSetCompletedByUserModal() {
        this.setState({ showSetCompletedByUserModal: false });
    }

    getAddress() {
        const lead = this.props.lead;
        return lead.street + " " + lead.houseNumber + ", " + lead.city;
    }

    render() {
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            );
        }

        const lead = this.props.lead;

        return (
            <React.Fragment>
                { lead !== null && (
                    <React.Fragment>
                        <LeadDetailSetStatusModal
                            show={ this.state.showSetStatusModal }
                            lead={ lead }
                            handleClose={ this.onHandleCloseSetStatusModal }
                            handleStatusChanged={ this.onHandleStatusChanged }
                            handleCommentAdded={ this.onCommentAdded }
                        />
                        <LeadDetailSetAssignedUserModal
                            show={ this.state.showSetAssignedUserModal }
                            lead={ lead }
                            handleClose={ this.onHandleCloseSetAssignedUserModal }
                            handleAssignedUserChanged={ this.onHandleAssignedUserChanged }
                        />
                        <LeadDetailSetCompletedByUserModal
                            show={ this.state.showSetCompletedByUserModal }
                            lead={ lead }
                            handleClose={ this.onHandleCloseSetCompletedByUserModal }
                            handleCompletedByUserChanged={ this.onHandleCompletedByUserChanged }
                        />
                        <AppleMapsModal
                            show={ this.state.showAppleMapsModal }
                            latitude={ lead.latitude }
                            longitude={ lead.longitude }
                            title={ lead.name }
                            emptyState="Er zijn geen coördinaten beschikbaar voor deze lead."
                            handleClose={ () => this.setState({ showAppleMapsModal: false }) }
                            refreshCoordinates={ this.refreshCoordinates.bind(this) }
                            refreshCoordinatesError={ this.state.errorCoordinates }
                        />
                        <LeadDetailNearLeadsModal
                            show={ this.state.showNearLeadsModal }
                            handleClose={ () => this.setState({ showNearLeadsModal: false }) }
                            refreshCoordinates={ this.refreshCoordinates.bind(this) }
                            refreshCoordinatesError={ this.state.errorCoordinates }
                            lead={ lead }
                        />
                    </React.Fragment>
                )}

                { lead !== null && lead.warnings.map((warning, index) => (
                    <Alert variant="warning" key={ index } className="d-print-none">{ warning }</Alert>
                ))}

                <DetailOverviewQRCode
                    content={ lead ? `lead=${lead.id}` : null }
                    caption="Scan deze code in de Sales app om deze lead direct te openen op je telefoon."
                />

                <div className="card">
                    <div className="card-body">
                        <table className="table table-borderless mb-0">
                            <tbody>
                            <DetailOverviewRow title="Naam">
                                { lead ? (
                                    <ClickCopy text={ lead.name }>
                                        { lead.name }
                                    </ClickCopy>
                                ) : <Skeleton width={200}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Toegewezen werknemer">
                                { lead ? (
                                    <React.Fragment>
                                        { lead.assignedUser ? lead.assignedUser.name : "Niemand" }
                                        <Button variant="link" size="sm" className="py-0" onClick={ () => this.setState({ showSetAssignedUserModal: true }) }>
                                            <i className="fas fa-pen"/>
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            { lead && lead.completedByUser && (
                                <DetailOverviewRow title="Verkocht door">
                                    { lead.completedByUser.name }
                                    <Button variant="link" size="sm" className="py-0" onClick={ () => this.setState({ showSetCompletedByUserModal: true }) }>
                                        <i className="fas fa-pen"/>
                                    </Button>
                                </DetailOverviewRow>
                            )}
                            <DetailOverviewRow title="Status">
                                { lead ? (
                                    <React.Fragment>
                                        <TagPill color={lead.status.color}>
                                            {lead.status.name}
                                        </TagPill>
                                        <Button variant="link" size="sm" className="py-0" onClick={ () => this.setState({ showSetStatusModal: true }) }>
                                            <i className="fas fa-pen"/>
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={175}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Adres">
                                { lead ? (
                                    <React.Fragment>
                                        <ClickCopy text={ this.getAddress() }>
                                            { this.getAddress() }
                                        </ClickCopy>
                                        <AddressMapButtons
                                            object={ lead }
                                            onOpenAppleMaps={ () => this.setState({ showAppleMapsModal: true }) }
                                        />
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Postcode">
                                { lead ? (
                                    <ClickCopy text={ lead.postalCode }>
                                        { lead.postalCode }
                                    </ClickCopy>
                                ) : <Skeleton width={75}/> }
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Telefoonnummer">
                                { lead ? (
                                    <CallLink phoneNumber={ lead.phoneNumber }/>
                                ) : (
                                    <Skeleton width={100}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Email">
                                { lead ? (
                                    <a href={"mailto:" + lead.email}>{lead.email}</a>
                                ) : (
                                    <Skeleton width={200}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Datum">
                                { lead ? (
                                    <React.Fragment>
                                        <DateFormatter date={lead.date}/>
                                        { lead.creationDate !== lead.date && (
                                            <span className="text-muted ml-2">
                                                (Aangemaakt op <DateFormatter date={lead.creationDate}/>)
                                            </span>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Type">
                                { lead ? (
                                    lead.type ? (
                                        <TagPill color={lead.type.color}>
                                            {lead.type.name}
                                        </TagPill>
                                    ) : (
                                        <TagPill color="#595959">
                                            Onbepaald
                                        </TagPill>
                                    )
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Bron">
                                { lead ? (
                                    <TagPill color={lead.source.color}>
                                        {lead.source.name}
                                    </TagPill>
                                ) : (
                                    <Skeleton width={175}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Aangemaakt door">
                                { lead ? (
                                    lead.author ? lead.author.name : (
                                        <i className="text-muted">Automatisch</i>)
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Locatie">
                                { lead ? (
                                    <React.Fragment>
                                        { lead.latitude && lead.longitude ? (
                                            <React.Fragment>
                                                { lead.latitude + ", " + lead.longitude }
                                            </React.Fragment>
                                        ) : (
                                            <i className="text-muted">Geen locatie beschikbaar</i>
                                        )}
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Coördinaten verversen</Tooltip>
                                        }>
                                            <Button onClick={ () => this.refreshCoordinates(lead.id) } variant="link" size="sm" className="ml-2 py-0">
                                                <i className="fas fa-sync"/>
                                            </Button>
                                        </OverlayTrigger>
                                        { lead.latitude && lead.longitude && (
                                            <CoordinatesMapButtons
                                                object={ lead }
                                                onOpenAppleMaps={ () => this.setState({ showAppleMapsModal: true }) }
                                            />
                                        )}
                                        { this.state.errorCoordinates !== null && (
                                            <Alert variant="danger" className="mb-0">{ this.state.errorCoordinates }</Alert>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="BAG bestemming">
                                { lead ? (
                                    lead.bagUsages ? (
                                        lead.bagUsages.join(", ")
                                    ) : (
                                        <i className="text-muted">Onbekend</i>
                                    )
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Afstand hemelsbreed">
                                { lead ? (
                                    lead.distance ? (
                                        <React.Fragment>
                                            <NumberFormatter number={ Math.ceil(lead.distance * 0.01) * 0.1 }/> km
                                        </React.Fragment>
                                    ) : (
                                        <i className="text-muted">Onbekend</i>
                                    )
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            { lead && lead.customerMessage && lead.customerMessage.length > 0 && (
                                <DetailOverviewRow title="Klant bericht">
                                    <ReactMarkdownBreaks message={ lead.customerMessage } noLinks/>
                                </DetailOverviewRow>
                            )}
                            <DetailOverviewRow title="Opmerkingen">
                                { lead ? (
                                    lead.message && lead.message.length > 0 ? (
                                        <ReactMarkdownBreaks message={ lead.message } noLinks/>
                                    ) : (
                                        <i className="text-muted">Leeg</i>
                                    )
                                ) : (
                                    <Skeleton count={3}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow>
                                { lead ? (
                                    <React.Fragment>
                                        <LeadExcelCopyButton
                                            lead={ lead }
                                            variant="secondary"
                                            size="sm"
                                            className="mr-2"
                                        >
                                            Excel calculatie kopie
                                        </LeadExcelCopyButton>
                                        <Link
                                            to={ "/lead/" + lead.id + "/print" }
                                            className="btn btn-secondary btn-sm mr-2"
                                        >
                                            Print opname formulier
                                        </Link>
                                        <Button
                                            variant="secondary"
                                            size="sm"
                                            onClick={ () => this.setState({ showNearLeadsModal: true }) }
                                        >
                                            Leads in de buurt
                                        </Button>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <span className="mr-2">
                                            <Skeleton width={ 148 } height={ 31 }/>
                                        </span>
                                        <Skeleton width={ 175 } height={ 31 }/>
                                    </React.Fragment>
                                )}
                            </DetailOverviewRow>
                            </tbody>
                        </table>
                    </div>
                </div>
                <DetailOverviewComments
                    comments={ this.state.comments }
                    error={ this.state.errorComments }
                    errorNewComment={ this.state.errorNewComment }
                    newCommentLoading={ this.state.newCommentLoading }
                    onSubmit={ this.addComment.bind(this) }
                    commentUpdated={ this.commentUpdated.bind(this) }
                    commentDeleted={ this.commentDeleted.bind(this) }
                    emptyState="Er staan nog geen notities onder deze lead."
                />
            </React.Fragment>
        )
    }
}

export default LeadDetailOverview;
