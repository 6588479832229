import React from "react";
import {
    TabBarItem,
    TabBarLabel
} from "@zandor300/backoffice-framework";

function InvoicingTabBarItems() {
    return (
        <React.Fragment>
            <TabBarLabel>Installaties</TabBarLabel>
            <TabBarItem to="/invoicing/installations">
                Openstaand
            </TabBarItem>
            <TabBarItem to="/invoicing/installations/paid">
                Betaald
            </TabBarItem>

            <TabBarLabel className="ml-4">Storingen</TabBarLabel>
            <TabBarItem to="/invoicing/outages">
                Openstaand
            </TabBarItem>
            <TabBarItem to="/invoicing/outages/paid">
                Betaald
            </TabBarItem>

            <TabBarLabel className="ml-4">Leads</TabBarLabel>
            <TabBarItem to="/invoicing/leads">
                Openstaand
            </TabBarItem>
            <TabBarItem to="/invoicing/leads/paid">
                Betaald
            </TabBarItem>
        </React.Fragment>
    );
}

export default React.memo(InvoicingTabBarItems);
