import React from "react";

function InstallationForm({ fields, setField, lead, saving }) {
    return (
        <React.Fragment>
            <div className="card mb-3">
                <div className="card-body">
                    <h4>Adres</h4>
                    <div className="custom-control custom-checkbox mb-2">
                        <input type="checkbox" className="custom-control-input" id="useLeadAddress" disabled={ saving }
                               checked={ fields.useLeadAddress }
                               onChange={ (event) => setField({ useLeadAddress: event.target.checked }) }
                        />
                        <label className="custom-control-label" htmlFor="useLeadAddress">
                            Hetzelfde als lead
                        </label>
                    </div>

                    { fields.useLeadAddress ? (
                        <p className="mb-0">
                            { lead.street + " " + lead.houseNumber }
                            <br/>
                            { lead.postalCode + " " + lead.city }
                        </p>
                    ) : (
                        <React.Fragment>
                            <div className="row">
                                <div className="col-sm-8">
                                    <div className="form-group">
                                        <label htmlFor="street">Straat</label>
                                        <input
                                            type="text" className="form-control" id="street" required disabled={ saving }
                                            value={fields.street}
                                            onChange={ (event) => setField({ street: event.target.value }) }
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="houseNumber">Huisnummer + Toevoeging</label>
                                        <input
                                            type="text" className="form-control" id="houseNumber" required disabled={ saving }
                                            value={fields.houseNumber}
                                            onChange={ (event) => setField({ houseNumber: event.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group mb-0">
                                        <label htmlFor="postalCode">Postcode</label>
                                        <input
                                            type="text" className="form-control" id="postalCode" required disabled={ saving }
                                            value={fields.postalCode}
                                            onChange={ (event) => setField({ postalCode: event.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group mb-0">
                                        <label htmlFor="city">Stad</label>
                                        <input
                                            type="text" className="form-control" id="city" required disabled={ saving }
                                            value={fields.city}
                                            onChange={ (event) => setField({ city: event.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>BTW Terugvraag</h4>
                    <div className="form-group mb-0">
                        <label htmlFor="hasVATRefund">BTW Terugvraag in offerte?</label>
                        <select
                            className="form-control" id="hasVATRefund" disabled={ saving }
                            value={ fields.hasVATRefund ? 1 : 0 }
                            onChange={ (event) => setField({
                                hasVATRefund: parseInt(event.target.value) === 1
                            })}
                        >
                            <option value={ 0 }>Geen BTW Terugvraag</option>
                            <option value={ 1 }>BTW Terugvraag</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>Beschrijving</h4>
                    <div className="form-group mb-0">
                        <textarea
                            className="form-control" id="description" rows={ 8 } disabled={ saving }
                            value={fields.description}
                            onChange={ (event) => setField({ description: event.target.value })}
                        />
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default React.memo(InstallationForm);
