import React from "react";
import moment from "moment";

import AbsenceCommentItem from "./AbsenceCommentItem";

function AbsenceCommentsRow({ comments, currentWeekStartMoment }) {
    const weekComments = [[], [], [], [], []];
    
    comments?.forEach(comment => {
        const commentDate = moment(comment.date, "YYYY-MM-DD");
        const dayIndex = commentDate.isoWeekday() - 1;
        
        if (dayIndex >= 0 && dayIndex < 5 && 
            commentDate.isoWeek() === currentWeekStartMoment.isoWeek() &&
            commentDate.isoWeekYear() === currentWeekStartMoment.isoWeekYear()) {
            weekComments[dayIndex].push(comment);
        }
    });

    return (
        <tr className="">
            {weekComments.map((dayComments, index) => (
                <td key={index}>
                    {dayComments.length > 0 ? (
                        dayComments.map((comment, commentIndex) => (
                            <AbsenceCommentItem 
                                key={commentIndex} 
                                comment={comment}
                            >
                                {comment.description ? comment.description : "Geen notitie"}
                            </AbsenceCommentItem>
                        ))
                    ) : (
                        <AbsenceCommentItem>
                            Geen notitie
                        </AbsenceCommentItem>
                    )}
                </td>
            ))}
        </tr>
    );
}

export default React.memo(AbsenceCommentsRow);
