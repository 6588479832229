import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    Modal,
    Table,
    Form,
} from "react-bootstrap";

import AppleMapsMultipleModal from "../../../../components/apple-maps/AppleMapsMultipleModal";
import NumberFormatter from "../../../../components/formatters/NumberFormatter";
import DistanceFormatter from "../../../../components/formatters/DistanceFormatter";
import Loading from "../../../../components/Loading";
import TagPill from "../../../../components/tagPill";

const distanceOptions = [0.05, 0.1, 0.2, 0.4, 0.75, 1, 2]; // in km

class LeadDetailNearLeadsModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            leads: null,
            distanceIndex: 0,
            error: null,
            showMapModal: false,
            mapAnnotations: null,
        };
        this.onShow = this.onShow.bind(this);
        this.handleDistanceChange = this.handleDistanceChange.bind(this);
        this.handleSliderRelease = this.handleSliderRelease.bind(this);
    }

    onShow() {
        this.getNearLeads();
    }

    componentDidUpdate(prevProps) {
        if (
            (!prevProps.latitude || !prevProps.longitude) &&
            (this.props.latitude && this.props.longitude)
        ) {
            this.getNearLeads();
        }
    }

    getNearLeads() {
        const distance = distanceOptions[this.state.distanceIndex];
        this.setState({ leads: null, error: null });
        axios
            .post("/getNearLeads", {
                leadId: this.props.lead.id,
                distance,
            })
            .then((response) => {
                if (response.data.valid) {
                    const mapAnnotations = response.data.data.map((lead) => ({
                        id: lead.id,
                        latitude: lead.latitude,
                        longitude: lead.longitude,
                        title: lead.name,
                    }));
                    this.setState({
                        leads: response.data.data,
                        annotations: mapAnnotations,
                    });
                } else {
                    this.setState({
                        error:
                            "Er ging iets fout bij het ophalen van de leads. (" +
                            response.data.error +
                            ")",
                    });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de leads." });
                console.error(error);
            });
    }

    handleDistanceChange(event) {
        const newIndex = parseInt(event.target.value, 10);
        this.setState({ distanceIndex: newIndex });
    }

    handleSliderRelease() {
        this.getNearLeads();
    }

    render() {
        const {
            show,
            handleClose,
            refreshCoordinates,
            refreshCoordinatesError,
            emptyState,
            lead
        } = this.props;
        const { leads, distanceIndex, error } = this.state;
        const selectedDistance = distanceOptions[distanceIndex];

        return (
            <React.Fragment>
                <Modal size="xl" show={show} onHide={handleClose} onShow={this.onShow}>
                    <Modal.Header closeButton>
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <Modal.Title className="d-flex align-items-center">
                                <span>{lead.name}</span>
                                {lead.latitude || lead.longitude ? (
                                    selectedDistance !== null && (
                                        <small className="text-muted ml-2">
                                            (<NumberFormatter number={Math.ceil(selectedDistance * 1000)} /> m) afstand
                                        </small>
                                    )
                                ) : null}
                            </Modal.Title>
                            <div>
                                <AppleMapsMultipleModal
                                    show={this.state.showMapModal}
                                    handleClose={() => this.setState({ showMapModal: false })}
                                    annotations={this.state.annotations}
                                    title={lead.name}
                                />
                                {lead.latitude || lead.longitude ? (
                                    <Button
                                        variant="secondary"
                                        size="sm"
                                        className="no-drag"
                                        onClick={() => this.setState({ showMapModal: true })}
                                    >
                                        <i className="fas fa-map fa-fw" />
                                    </Button>
                                ) : null}
                            </div>
                        </div>
                    </Modal.Header>
                    {lead.latitude || lead.longitude ? (
                        <div className="m-3">
                            <Form.Control
                                className="w-100"
                                type="range"
                                value={distanceIndex}
                                onChange={this.handleDistanceChange}
                                onMouseUp={this.handleSliderRelease}
                                onTouchEnd={this.handleSliderRelease}
                                min={0}
                                max={distanceOptions.length - 1}
                                step={1}
                            />
                        </div>
                    ) : null}
                    <Modal.Body className="p-0">
                        {!lead.latitude || !lead.longitude ? (
                            <div className="p-3 h-100">
                                {refreshCoordinatesError && (
                                    <Alert variant="danger">{refreshCoordinatesError}</Alert>
                                )}
                                <div className="d-flex align-items-center w-100 h-100">
                                    <div className="w-100 text-center">
                                        <h1>
                                            <i className="fas fa-map-marker-times" />
                                        </h1>
                                        <h3>Coördinaten niet beschikbaar</h3>
                                        <p>{emptyState}</p>
                                        <div className="btn-toolbar d-block mx-auto">
                                            {refreshCoordinates && (
                                                <div className="btn-group mr-2">
                                                    <Button variant="primary" onClick={refreshCoordinates}>
                                                        Coördinaten bijwerken
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : error ? (
                            <div className="p-2">
                                <Alert variant="danger" className="m-0">
                                    {error}
                                </Alert>
                            </div>
                        ) : !leads ? (
                            <div className="p-2">
                                <Loading />
                            </div>
                        ) : leads.length === 0 ? (
                            <div className="text-center">
                                <h1><i className="fa-solid fa-location-dot-slash" /></h1>
                                <h3>Geen leads</h3>
                                <p>Geen leads gevonden in de buurt.</p>
                            </div>
                        ) : (
                            <Table hover size="sm" className="mb-0">
                                <thead className="thead-light">
                                    <tr className="tr-sticky">
                                        <th className="text-center">#</th>
                                        <th className="text-right">Afstand</th>
                                        <th>Status</th>
                                        <th>Naam</th>
                                        <th>Adres</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leads.map((lead) => {
                                        const url = `/lead/${lead.id}`;
                                        return (
                                            <tr className="table-clickable-row" key={lead.id}>
                                                <td className="align-middle text-center">
                                                    <Link to={url}>
                                                        <b>{lead.id}</b>
                                                    </Link>
                                                </td>
                                                <td className="align-middle text-right">
                                                    <Link to={url}>
                                                        <DistanceFormatter distance={lead.distance} />
                                                    </Link>
                                                </td>
                                                <td className="align-middle">
                                                    <Link to={url}>
                                                        <TagPill color={lead.status.color}>
                                                            {lead.status.name}
                                                        </TagPill>
                                                    </Link>
                                                </td>
                                                <td className="align-middle">
                                                    <Link to={url}>{lead.name}</Link>
                                                </td>
                                                <td className="align-middle">
                                                    <Link to={url}>
                                                        {lead.street + " " + lead.houseNumber + ", " + lead.city}
                                                    </Link>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Sluiten
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

export default LeadDetailNearLeadsModal;
