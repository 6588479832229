import React from "react";
import axios from "axios";
import {
    withRouter
} from "react-router-dom";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";
import DetailOverviewRow from "../../components/detail/DetailOverviewRow";
import InstallationForm from "./detail/components/InstallationForm";
import CallLink from "../../components/detail/CallLink";

class InstallationAdd extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            lead: null,
            saving: false,

            useLeadAddress: true,
            street: "",
            houseNumber: "",
            postalCode: "",
            city: "",

            hasVATRefund: false,

            description: ""
        };
        this.addInstallation = this.addInstallation.bind(this);
    }

    componentDidMount() {
        this.getLead();
    }

    getLead() {
        this.setState({ lead: null, loadError: null });
        axios.post("/getLead", { id: this.props.match.params.leadId })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ lead: response.data.data });
                } else {
                    this.setState({ loadError: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    addInstallation() {
        if(!this.canSubmit()) {
            this.setState({ error: "Niet alle vereiste velden zijn ingevuld." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        this.setState({ error: null, saving: true });
        axios.post("/addInstallation", {
            leadId: this.state.lead.id,

            useLeadAddress: this.state.useLeadAddress ? 1 : 0,
            street: this.state.street,
            houseNumber: this.state.houseNumber,
            postalCode: this.state.postalCode,
            city: this.state.city,

            hasVATRefund: this.state.hasVATRefund ? 1 : 0,

            description: this.state.description
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.history.push("/installation/" + response.data.installation.id);
                } else {
                    this.setState({ saving: false, error: "Er was een fout bij het opslaan van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ saving: false, error: "Er was een fout bij het opslaan van de data." });
            })
            .finally(() => {
                window.scroll({ top: 0, behavior: "smooth" });
            });
    }

    canSubmit() {
        if(!this.state.useLeadAddress) {
            if(this.state.street.length === 0 || this.state.houseNumber.length === 0 || this.state.postalCode.length === 0 || this.state.city.length === 0) {
                return false;
            }
        }
        if(this.state.hasVATRefund === null) {
            return false;
        }
        return true;
    }

    render() {
        const lead = this.state.lead;
        return (
            <React.Fragment>
                <Helmet title="Nieuwe installatie toevoegen"/>
                <Title preTitle="Detail">
                    Nieuwe installatie toevoegen
                </Title>

                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}

                { !lead ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h4>
                                    Geselecteerde lead
                                </h4>

                                <table className="table table-borderless mb-0">
                                    <tbody>
                                    <DetailOverviewRow title="Naam" small>
                                        { lead.name }
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Adres" small>
                                        { lead.street + " " + lead.houseNumber + ", " + lead.city }
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Postcode" small>
                                        { lead.postalCode }
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Telefoonnummer" small>
                                        <CallLink phoneNumber={ lead.phoneNumber }/>
                                    </DetailOverviewRow>
                                    <DetailOverviewRow title="Email" small>
                                        <a href={ "mailto:" + lead.email }>{ lead.email }</a>
                                    </DetailOverviewRow>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <InstallationForm
                            fields={ this.state }
                            setField={ (state) => this.setState({ ...state }) }
                            lead={ lead }
                            saving={ this.state.saving }
                        />

                        <Button variant="primary" className="float-right" onClick={ this.addInstallation } disabled={ this.state.saving }>
                            { this.state.saving && (
                                <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                            )}
                            Toevoegen
                        </Button>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default withRouter(InstallationAdd);
