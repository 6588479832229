import axios from "axios";

import DetailSetStatusModal from "../../../../components/detail/modal/DetailSetStatusModal";

class OutageDetailSetInvoicingStatusModal extends DetailSetStatusModal {
    getInitialStatus() {
        return this.props.outage.invoicingStatus?.id;
    }

    shouldEnterReason() {
        return false;
    }

    getStatuses() {
        this.setState({ statuses: null, error: null });
        axios.get("/getOutageInvoicingStatuses")
            .then((response) => {
                if(response.data.valid) {
                    let statuses = response.data.data;
                    statuses.sort((status1, status2) => {
                        if(status1.priority > status2.priority) return -1;
                        if(status1.priority < status2.priority) return 1;
                        return 0;
                    });
                    this.setState({ statuses });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    setStatus(onSuccess, onError) {
        axios.post("/setOutageInvoicingStatus", { outageId: this.props.outage.id, invoicingStatusId: this.state.selectedStatus })
            .then((response) => {
                if(response.data.valid) {
                    onSuccess(() => {
                        this.props.handleStatusChanged(response.data.outage);
                    });
                } else {
                    onError("Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                onError("Er ging iets fout. Probeer het later opnieuw.");
            });
    }

    addReasonComment(onSuccess, onError) {
    }

}

export default OutageDetailSetInvoicingStatusModal;
