import React from "react";
import moment from "moment";

import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";

function AbsenceCommentItem({ comment, children }) {
    const today = moment().startOf("day");
    const commentDate = moment(comment?.date).startOf("day");
    const beforeToday = commentDate.isBefore(today);

    return (
        <div className="d-flex">
            <div className={ "card flex-grow-1 mb-2" + ((beforeToday || comment?.description === undefined) ? " text-muted" : "") }>
                <div className="card-body p-1">
                    <ReactMarkdownBreaks message={ comment?.description ?? children }/>
                </div>
            </div>
        </div>
    );
}

export default React.memo(AbsenceCommentItem);
